
export const URLS_DEV = {
  APP: `http://localhost:3000/`,
  API: `http://localhost:7003/api/`,
  WS: `ws://localhost:7003`
};

// export const URLS_PROD = {
//   APP: `http://178.62.211.237:7010/`,
//   API: `http://178.62.211.237:7000/api/`,
//   WS: `http://178.62.211.237:7000/ws`
// };
export const URLS_PROD = {
  APP: `https://sicob.codigoblanco.mx/`,
  API: `https://apisistemaintegral.codigoblanco.mx/api/`,
  WS: `https://apisistemaintegral.codigoblanco.mx`
};


export const TYPES_USERS = {
  SPADMIN: 'SUPERADMIN'
};

const ANDROID = 'ANDROID';
const IOS     = 'IOS';
const BACKEND = 'BACKEND';
const SPECIAL = 'SPECIAL';
const URL     = 'URL';

export const platforms = {
  ANDROID:  ANDROID,
  IOS:      IOS,
  BACKEND:  BACKEND,
  SPECIAL:  SPECIAL,
  URL:      URL
};

export const MONEDA = {
  DOLAR:  'DOLAR',
  PESO:   'PESO'
};

export const KITS = {
  HIBRIDO: 'HIBRIDO',
  INALAMBRICO: 'INALAMBRICO',
  INTERACTIVO: 0,
  NO_INTERACTIVO: 1
};

export const PENDIENTE = 'PENDIENTE';
export const ABONADA = 'ABONADA';
export const PAGADA = 'PAGADA';
export const PAGADA_MANUAL = 'PAGADA_MANUAL';
export const CANCELADA = 'CANCELADA';

export const DECLINADA = 'DECLINADA';
export const INICIADA = 'INICIADA';
export const AUTORIZADA = 'AUTORIZADA';
export const FINALIZADA = 'FINALIZADA';
export const PAUSADA = 'PAUSADA';

export const TEMPORALIDAD = {
  DIARIO: 'DIARIO',
  SEMANAL: 'SEMANAL',
  MENSUAL: 'MENSUAL',
  ANUAL: 'ANUAL',
  EVENTO: 'EVENTO'
};

/**
 * https://momentjs.com/docs/#/manipulating/add/
 */
export const TYPE_RANKS = {
  MONTH:  'months',
  WEEK:   'weeks',
  DAY:    'days',
  HOUR:   'hours',
  MINUTE: 'minutes'
};

export const SESSION_EXPIRATION = {
  TIME: 4,
  RANK: TYPE_RANKS.HOUR
};

export const CLASSIFICATIONS = {
  SERVICE: 'SERVICE',
  PRODUCT: 'PRODUCT'
};
export const PRODUCTION = 'PRODUCTION';
export const DEVELOPMENT = 'DEVELOPMENT';


export const KIT = 'KIT';
export const ADICIONAL = 'ADICIONAL';

export const CULIACAN = {
  LATITUDE:   24.80481147653668,
  LONGITUDE:  -107.39376068115234
};

export const TYPES_USERS_FRONT = {
  ADMIN:  'ADMIN',
  MEMBER: 'SOCIO'
};

export const TIPO_SERVICIOS = {
  MONITOREO:   'MONITOREO',
  INTERACTIVO: 'INTERACTIVO',
  MANO_OBRA: 'MANO DE OBRA'
};

export const TYPE_CONFIGURATIONS = {
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE'
};

export const COLOR_C_B = '#363636';

export const COLORS = {
  BLUE: '#363636',
  ORANGE: '#EF6C00'
};

export const OTRO_CABLEADO = 'OTROS ESPECIFICAR';

export const INDETERMINADO = 'INDETERMINADO';
export const FORZOSO = 'FORZOSO';

const PENDING_PAY           = 'Pendiente de Pago';
const PENDING_CONTRACT      = 'Contrato Pendiente';
const INSTALLATION_PENDING  = 'Instalación pendiente';

export const STATUS_QUOTATIONS = {
  PENDING_PAY, PENDING_CONTRACT, INSTALLATION_PENDING
};

export const TIPOS_DOMICILIOS = {
  PRINCIPAL: 'PRINCIPAL',
  SUCURSAL: 'SUCURSAL'
};

export const ALLOWED = [3220, 3921, 3056, 11, 636, 2824, 2888, 2985, 1328, 3260];

/*
3220  - CECILIO LOPEZ ESCOBAR
3921  - ESMERALDA ROSIO COSSIO
3056  - GONHERMEX SA DE CV
11    - AGRCIOLA ASHLEY SA DE CV
636   - JESUS MARIA TARRIBA ROJO
2824  - GERARDO CAMACHO PEREZ
2888  - ZAPATERIA FLORI
2985  - ESPERANZA FLORES ORTEGA
1328  - COMERCIAL AUTOMOTRIZ DEL NOROESTE SA DE CV
3260  - IMPULSO RURAL SA DE CV
*/

export const OXXO = 'OXXO';
export const SPEI = 'SPEI';
export const TARJETA = 'TARJETA';
export const PREELABORADO = 'PREELABORADO';

export const PESO = 'PESO';
export const DOLAR = 'DOLAR';
export const EURO = 'EURO';

export const ENCABEZADOS = {
  D1_30: 1,
  D31_60: 2,
  D61_90: 3,
  D91_120: 4,
  D121: 5
};

export const MIME_TYPES = {
  PDF:  'application/pdf',
  DOC:  'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS:  'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT:  'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  JPEG: 'image/jpeg',
  PNG: 'image/png'
};
