import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAuthContext } from '../../context/authContext';

export default function PrivateRoute(props) {
    const { token } = useAuthContext();
    if (!token)
        return <Navigate to={'login'} ></Navigate>;
    return (
        <div className="content-wrapper">
            <Outlet/>
        </div>
    );
}   