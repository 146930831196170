import { isAuthorized } from 'utils/permissions';

export const requireAuth = (nextState, replace, wrappedNext, action) => {
  if ( ! isAuthorized(nextState, action)  ) {
    // redirect({ path: '/sin-autorizacion', nextState, replace });
  }
  return wrappedNext();
};

const redirect = ({ path, nextState, replace }) => {
  replace({
    pathname: path,
    state: {
      nextPathname: nextState.location.pathname
    }
  });
};
