import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'context/authContext';
import { useLocation } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { CustomerOption } from 'stateless';
import { getRequest } from 'utils/api';

const activeTab = (location, path) => {
  if (location.pathname === path) {
    return { fontWeight: '600', backgroundColor: '#e5e7eb' };
  }
};

export default function Menu() {
  const { user, token } = useAuthContext();
  const [openItem, setOpenItem] = useState(false);
  const [cliente, setCliente] = useState(false);
  let location = useLocation();
  let modules = user ? user.modulesAssing: [];
  const moduloActivo = (modulo) => {
    if(!modules) return false;
    return modules.filter((module) => {
      const moduleName = typeof module.subModulesID === 'undefined' ? module : module.moduleID;
      const moduleSubs = typeof module.subModulesID === 'undefined' ? module.subModules : module.subModulesID;
      if(modulo === moduleName.module){
        if(moduleSubs.length > 0){
          return moduleSubs.filter((subModule) => {
            return location.pathname.indexOf(subModule.path) >= 0;
          }).length > 0;
        }
      }
      return false;
    }).length > 0;
  }

  const onClickOpen = (e, item)=> {
    setOpenItem(item);
  }
  if(!token){
    return null;
  }


  
  const loadOptions = (inputValue, callback) => {
    getRequest({
      url: `customers-autocomplete?query=${inputValue}`,
      success: ( response ) => {
        callback(response.data);
      },
    });
  }

  
  const onChangeCliente = (itemSelect)  => {
    setCliente(itemSelect)
  }

  return (
    <aside className="main-sidebar">
      <section className="sidebar">
        <ul className="sidebar-menu tree" data-widget="tree">
          <li className="header">MÉNU PRINCIPAL</li>
          {/* <li className="header" style={{overflow: 'revert'}}>
            <AsyncSelect
                value={cliente}
                loadOptions={loadOptions}
                onChange={onChangeCliente}
                getOptionLabel={(x)=> `${x.razon_social}` }
                getOptionValue={(x)=> x._id }
                isClearable
                placeholder={'Buscar cliente... '}
                components={{ Option: CustomerOption }}
              />
          </li> */}
          { modules.map((module, itemModule) => {
            const moduleName = typeof module.subModulesID === 'undefined' ? module : module.moduleID;
            const moduleSubs = typeof module.subModulesID === 'undefined' ? module.subModules : module.subModulesID;
            if ( moduleSubs.length > 0 ) {
              return (
                <li className={ 'treeview ' + (moduloActivo(moduleName.module) ? ' active ': '') + ( openItem === itemModule ? ' menu-open ' : '') } key={itemModule} >
                  <a href="#" onClick={(e) => onClickOpen(e,itemModule)}>
                    <i className={`glyphicon glyphicon-${moduleName.icon}`}></i>
                    <span>{(moduleName.module).toUpperCase()}</span>
                  </a>
                  <ul className={'treeview-menu'} style={{ display: openItem === itemModule ? 'block': 'none' }}>
                    {
                      moduleSubs.map((subModule, itemSubModule) => {
                        return (
                          <li key={itemModule + itemSubModule} >
                            <Link to={`/${subModule.path}`} style={activeTab(location, `/${subModule.path}`)}>
                              <i className={`glyphicon glyphicon-menu-right`}></i>
                              {subModule.name}
                            </Link>
                          </li>
                        );
                      })
                    }
                  </ul>
                </li>
              );
            }
          }) }
        </ul>
      </section>
    </aside>
  );
}