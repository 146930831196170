import moment from 'moment';

export const getDatePrograming = (params) => {
  const month = getMonthspanish(params.getMonth());
  return {
    date: params.getDate(),
    monthLetter: month.letter,
    monthNumber: month.number,
    year: params.getFullYear()
  };
};

export const getMonthspanish = (monthSelect) => {
  const catMonths = {
    0: () => {
      return { letter: 'enero', abbreviation: 'ene', number: '01' };
    },
    1: () => {
      return { letter: 'febrero', abbreviation: 'feb', number: '02' };
    },
    2: () => {
      return { letter: 'marzo', abbreviation: 'mar', number: '03' };
    },
    3: () => {
      return { letter: 'abril', abbreviation: 'abr', number: '04' };
    },
    4: () => {
      return { letter: 'mayo', abbreviation: 'may', number: '05' };
    },
    5: () => {
      return { letter: 'junio', abbreviation: 'jun', number: '06' };
    },
    6: () => {
      return { letter: 'julio', abbreviation: 'jul', number: '07' };
    },
    7: () => {
      return { letter: 'agosto', abbreviation: 'ago', number: '08' };
    },
    8: () => {
      return { letter: 'septiembre', abbreviation: 'sep', number: '09' };
    },
    9: () => {
      return { letter: 'octubre', abbreviation: 'oct', number: '10' };
    },
    10: () => {
      return { letter: 'noviembre', abbreviation: 'nov', number: '11' };
    },
    11: () => {
      return { letter: 'diciembre', abbreviation: 'dic', number: '12' };
    },
  };
  return catMonths[monthSelect]();
};

export const getInitFinishDates = (date) => {
  const monday    = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const tuesday   = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const wednesday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const thursday  = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const friday    = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const saturday  = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const calculate = calculateRangeDaysDefault(date.getDay());

  monday.setDate(monday.getDate() + calculate.mon);
  tuesday.setDate(tuesday.getDate() + calculate.tue);
  wednesday.setDate(wednesday.getDate() + calculate.wed);
  thursday.setDate(thursday.getDate() + calculate.thu);
  friday.setDate(friday.getDate() + calculate.fri);
  saturday.setDate(saturday.getDate() + calculate.sat);

  return {
    monday: getDatePrograming(monday),
    tuesday: getDatePrograming(tuesday),
    wednesday: getDatePrograming(wednesday),
    thursday: getDatePrograming(thursday),
    friday: getDatePrograming(friday),
    saturday: getDatePrograming(saturday)
  };
};

export const calculateRangeDaysDefault = (date) => {
  const numDate = {
    0: () => {
      return { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
    },
    1: () => {
      return { sun: -1, mon: 0, tue: 1, wed: 2, thu: 3, fri: 4, sat: 5 };
    },
    2: () => {
      return { sun: -2, mon: -1, tue: 0, wed: 1, thu: 2, fri: 3, sat: 4 };
    },
    3: () => {
      return { sun: -3, mon: -2, tue: -1, wed: 0, thu: 1, fri: 2, sat: 3 };
    },
    4: () => {
      return { sun: -4, mon: -3, tue: -2, wed: -1, thu: 0, fri: 1, sat: 2 };
    },
    5: () => {
      return { sun: -5, mon: -4, tue: -3, wed: -2, thu: -1, fri: 0, sat: 1 };
    },
    6: () => {
      return { sun: -6, mon: -5, tue: -4, wed: -3, thu: -2, fri: -1, sat: 0 };
    }
  };
  return numDate[date]();
};

export const calculateRangeDaysSearch = (date) => {
  const numDate = {
    0: () => {
      return { mon: -6, tue: -5, wed: -4, thu: -3, fri: -2, sat: -1, sun: 0 };
    },
    1: () => {
      return { mon: 0, tue: 1, wed: 2, thu: 3, fri: 4, sat: 5, sun: 6 };
    },
    2: () => {
      return { mon: -1, tue: 0, wed: 1, thu: 2, fri: 3, sat: 4, sun: 5 };
    },
    3: () => {
      return { mon: -2, tue: -1, wed: 0, thu: 1, fri: 2, sat: 3, sun: 4 };
    },
    4: () => {
      return { mon: -3, tue: -2, wed: -1, thu: 0, fri: 1, sat: 2, sun: 3 };
    },
    5: () => {
      return { mon: -4, tue: -3, wed: -2, thu: -1, fri: 0, sat: 1, sun: 2 };
    },
    6: () => {
      return { mon: -5, tue: -4, wed: -3, thu: -2, fri: -1, sat: 0, sun: 1 };
    }
  };
  return numDate[date]();
};

/**
 * Parse nombre de los días
 */
export const nameDay = (day) => {
  const name_days = {
    0: () => {
      return 'Domingo';
    },
    1: () => {
      return 'Lunes';
    },
    2: () => {
      return 'Martes';
    },
    3: () => {
      return 'Miércoles';
    },
    4: () => {
      return 'Jueves';
    },
    5: () => {
      return 'Viernes';
    },
    6: () => {
      return 'Sábado';
    }
  };
  return name_days[day]();
};

/**
 * Parse de Dias de la Semana
 */
export const catalogWeek = {
  monday: {
    field: 'monday',
    name: 'Lunes'
  },
  tuesday: {
    field: 'tuesday',
    name: 'Martes'
  },
  wednesday: {
    field: 'wednesday',
    name: 'Miércoles'
  },
  thursday: {
    field: 'thursday',
    name: 'Jueves'
  },
  friday: {
    field: 'friday',
    name: 'Viernes'
  },
  saturday: {
    field: 'saturday',
    name: 'Sábado'
  },
  sunday: {
    field: 'sunday',
    name: 'Domingo'
  }
};

/**
 * Formatear una campo date de MongoDB al formato de fecha dd-mmmm-yy hh-mm am/pm
 */
export const formatDate = (dateVal) => {
  var newDate = new Date(dateVal);

  var sMonth  = padValue(newDate.getMonth() + 1);
  var sDay    = padValue(newDate.getDate());
  var sYear   = newDate.getFullYear();
  var sHour   = newDate.getHours();
  var sMinute = padValue(newDate.getMinutes());
  var sAMPM   = 'AM';

  var iHourCheck = parseInt(sHour);

  if (iHourCheck > 12) {
    sAMPM = 'PM';
    sHour = iHourCheck - 12;
  }else if (iHourCheck === 0) {
    sHour = '12';
  }

  sHour = padValue(sHour);

  return `${sDay} de ${monthLetter(sMonth).toLowerCase()} ${sYear} - ${sHour}:${sMinute} ${sAMPM}`;
};

const padValue = (value) => {
  return (value < 10) ? '0' + value : value;
};

const monthLetter = (month) => {
  const months = {
    '01': () => {
      return 'Enero';
    },
    '02': () => {
      return 'Febrero';
    },
    '03': () => {
      return 'Marzo';
    },
    '04': () => {
      return 'Abril';
    },
    '05': () => {
      return 'Mayo';
    },
    '06': () => {
      return 'Junio';
    },
    '07': () => {
      return 'Julio';
    },
    '08': () => {
      return 'Agosto';
    },
    '09': () => {
      return 'Septiembre';
    },
    '10': () => {
      return 'Octubre';
    },
    '11': () => {
      return 'Noviembre';
    },
    '12': () => {
      return 'Diciembre';
    }
  };

  return months[month]();
};

export const getDataCompleteDates = (dateInit, dateFinish) => {
  const initDate    = new Date(dateInit);
  const finishDate  = new Date(dateFinish);
  const monthStartOriginal  = initDate.getMonth() + 1;
  const monthEndOriginal    = finishDate.getMonth() + 1;

  const startOriginal = {
    date: initDate.getDate() < 10 ? '0'+ initDate.getDate() : initDate.getDate(),
    month: monthStartOriginal < 10 ? '0' + monthStartOriginal: monthStartOriginal,
    year: initDate.getFullYear()
  };

  const endOriginal = {
    date: finishDate.getDate() < 10 ? '0'+ finishDate.getDate() : finishDate.getDate(),
    month: monthEndOriginal < 10 ? '0' + monthEndOriginal: monthEndOriginal,
    year: finishDate.getFullYear()
  };

  const start = moment(`${startOriginal.year}-${startOriginal.month}-${startOriginal.date}`);
  const end   = moment(`${endOriginal.year}-${endOriginal.month}-${endOriginal.date}`);

  const numberDays  = end.diff(start, 'days') + 1;
  const arrayDates  = [];

  for (var i = 0; i < numberDays; i++) {
    const dateStart = new Date(start.year(), start.month(), start.date());
    const date      = new Date(start.year(), start.month(), start.date());

    date.setDate(dateStart.getDate() + i);
    arrayDates.push(date);
  }

  return { numberDays, arrayDates };
};

export const setupFormatDate = date => {
  const month = date.getMonth() + 1;

  return {
    date:     date.getDate() < 10 ? '0'+ date.getDate() : date.getDate(),
    month:    month < 10 ? `0${month}` : month,
    year:     date.getFullYear(),
    hours:    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
    minutes:  date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes(),
  };
};

export const setupFormatDateMoment = date => {
  const month = date.month() + 1;

  return {
    date:   date.date() < 10 ? '0'+ date.date() : date.date(),
    month:  month < 10 ? `0${month}` : month,
    year:   date.year()
  };
};

export const printFormalDate = paramDate => {
  const dateNative = new Date(paramDate);
  const monthIncrement = dateNative.getMonth() + 1;

  const year    = dateNative.getFullYear();
  const month   = monthIncrement < 10 ? '0' + monthIncrement : monthIncrement;
  const date    = dateNative.getDate() < 10 ? `0${dateNative.getDate()}` : dateNative.getDate();
  const hours   = dateNative.getHours() < 10 ? `0${dateNative.getHours()}` : dateNative.getHours();
  const minutes = dateNative.getMinutes() < 10 ? `0${dateNative.getMinutes()}` : dateNative.getMinutes();

  return `${moment(`${year}-${month}-${date} ${hours}:${minutes}`).local().format('DD [de] MMMM [de] YYYY')}`;
};

export const printFormalDateTime = paramDate => {
  const dateNative = new Date(paramDate);
  const monthIncrement = dateNative.getMonth() + 1;

  const year    = dateNative.getFullYear();
  const month   = monthIncrement < 10 ? '0' + monthIncrement : monthIncrement;
  const date    = dateNative.getDate() < 10 ? `0${dateNative.getDate()}` : dateNative.getDate();
  const hours   = dateNative.getHours() < 10 ? `0${dateNative.getHours()}` : dateNative.getHours();
  const minutes = dateNative.getMinutes() < 10 ? `0${dateNative.getMinutes()}` : dateNative.getMinutes();

  return `${moment(`${year}-${month}-${date} ${hours}:${minutes}`).local().format('DD [de] MMMM [de] YYYY, h:mm a')}`;
};

export const printFormalTime = paramDate => {
  const dateNative = new Date(paramDate);
  const monthIncrement = dateNative.getMonth() + 1;

  const year    = dateNative.getFullYear();
  const month   = monthIncrement < 10 ? '0' + monthIncrement : monthIncrement;
  const date    = dateNative.getDate() < 10 ? `0${dateNative.getDate()}` : dateNative.getDate();
  const hours   = dateNative.getHours() < 10 ? `0${dateNative.getHours()}` : dateNative.getHours();
  const minutes = dateNative.getMinutes() < 10 ? `0${dateNative.getMinutes()}` : dateNative.getMinutes();

  return `${moment(`${year}-${month}-${date} ${hours}:${minutes}`).local().format('h:mm a')}`;
};

export const printFormalDateComplete = paramDate => {
  const dateNative = new Date(paramDate);
  const monthIncrement = dateNative.getMonth() + 1;

  const year    = dateNative.getFullYear();
  const month   = monthIncrement < 10 ? '0' + monthIncrement : monthIncrement;
  const date    = dateNative.getDate() < 10 ? `0${dateNative.getDate()}` : dateNative.getDate();
  const hours   = dateNative.getHours() < 10 ? `0${dateNative.getHours()}` : dateNative.getHours();
  const minutes = dateNative.getMinutes() < 10 ? `0${dateNative.getMinutes()}` : dateNative.getMinutes();

  return `${moment(`${year}-${month}-${date} ${hours}:${minutes}`).local().format('h:mm a DD[/]MMM[/]YY')}`;
};


export const printDiffDate = (dateTime, format = 'YYYY-MM-DDTHH:mm:ssZ') => {
  let start = moment(dateTime, format);
  let end   = moment();
  return start.from(end);
  /*
    let a  = end.diff(start, 'years');
    let me  = end.diff(start, 'month');
    let d  = end.diff(start, 'days');

    let h  = end.diff(start, 'hours');
    let m  = end.diff(start, 'minutes') - (60 * h);
    let s  = end.diff(start, 'seconds') - (60 * 60 * h) - (60 * m);
    if(a > 0){
      if(a == 1)
        return `${a} año`;
      return `${a} años`;
    } else if(me > 0){
      if(me == 1)
        return `${me} mes`;
      return `${me} meses`;
    } else if(d > 0){
      if(d == 1)
        return `${d} hr`;
      return `${d} hrs`;
    } else if(h > 0){
      if(h == 1)
        return `${h} hr`;
      return `${h} hrs`;
    } else if(m > 0){
      if(h == 1)
        return `${m} min`;
      return `${m} mins`;
    }
    if(s == 1)
      return `${s} seg`;
    return `${s} segs`;

   * */
};
