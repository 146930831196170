import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import NavBar from 'components/application/home/NavBar';
import Menu from 'components/application/home/Menu';
import ErrorBoundary from 'components/ErrorBoundary';
import { Outlet } from 'react-router';
import { AuthContext } from '../context/authContext';
import cache from 'utils/cache';
// Grande
// Colapsed

// sidebar-mini layout-fixed layout-navbar-fixed sidebar-collapse

// No colapsed

// sidebar-mini layout-fixed layout-navbar-fixed

// Chico
// Colapsed
// sidebar-mini layout-fixed layout-navbar-fixed sidebar-collapse sidebar-closed

// No colapsed
// sidebar-mini layout-fixed layout-navbar-fixed sidebar-closed sidebar-collapse

class App extends Component {
  static contextType = AuthContext; 
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loading: false,
      tasks: [],
      notifications: [],
      messages: [],
      show: false
    };

    this.onClickCollapsed = this.onClickCollapsed.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    cache();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if(this.context.collapsedNavbar){
      document.body.classList.add('sidebar-collapse');
    }
  }

  componentDidUpdate(){
    if(this.context.collapsedNavbar){
      document.body.classList.add('sidebar-collapse');
    }
  }

  componentWillMount() {
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if(window.innerWidth > 990) {
    // sidebar-closed sidebar-collapse
      if(!(this.context.collapsedNavbar)){
        document.body.classList.remove('sidebar-collapse');
      }
      document.body.classList.remove('sidebar-closed');
      document.body.classList.remove('sidebar-open');
    } else {
      if(this.context.collapsedNavbar){
        document.body.classList.add('sidebar-collapse');
      }
      document.body.classList.add('sidebar-closed');
      document.body.classList.remove('sidebar-open');
    }
  }

  // alert(params) {
  //   this.setState({
  //     dataSweetAlert: params,
  //     show: true
  //   });
    
  //   // if(params.success){
  //   //   MySwal.fire({
  //   //     title: params.title,
  //   //     text: params.message,
  //   //     icon: params.type || 'warning',
  //   //     showCancelButton: true,
  //   //     confirmButtonColor: '#3085d6',
  //   //     cancelButtonColor: '#d33',
  //   //     confirmButtonText: params.confirmButtonText || 'Si',
  //   //     cancelButtonText: params.cancelButtonText || 'Cancelar',
  //   //   }).then((result) => {
  //   //     if (result.isConfirmed && params.success) {
  //   //       params.success();
  //   //     }else if(params.cancel){
  //   //       params.cancel();
  //   //     }
  //   //   });
  //   // } else {
  //   //   MySwal.fire({
  //   //     // text: params.message,
  //   //     title: params.message,
  //   //     icon: params.type,
  //   //     // position: 'top-end',
  //   //     showConfirmButton: false,
  //   //     timer: 1500,
  //   //   });
  //   // }
  // }

  onClickCollapsed(){
    this.collapsedMenu();
  }

  collapsedMenu(){
    if(document.body.classList.contains('sidebar-collapse')) {
      document.body.classList.remove('sidebar-collapse');
    } else {
      document.body.classList.add('sidebar-collapse');
    }
    if(document.body.classList.contains('sidebar-closed')) {
      document.body.classList.remove('sidebar-closed');
      document.body.classList.add('sidebar-open');
    } else if(document.body.classList.contains('sidebar-open')) {
      document.body.classList.remove('sidebar-open');
      document.body.classList.add('sidebar-closed');
    }
  }

  onClickCollapsedMenu(){
    if(window.innerWidth < 768 && document.body.classList.contains('sidebar-open')) {
      document.body.classList.add('sidebar-collapse');
      document.body.classList.add('sidebar-closed');
      document.body.classList.remove('sidebar-open');
    }
  }

  render() {
    const { loaded } = this.state;
    return (
      <ErrorBoundary {...this.props}>
        <div className="main-header">
          <NavBar
            {...this.props}
            onClickCollapsed={this.onClickCollapsed}
            loaded={loaded}
            loggedIn={this.context.token}
            ambiente={this.context.user && this.context.user.ambiente}
            userId={this.context.user && this.context.user.id}
          />
        </div>
        <Menu {...this.props}/>
        <Outlet/>
        <footer className="main-footer" style={!this.context.token ? { marginLeft: '0px' } : {}}>
          <div className="pull-right hidden-xs">
            <b>Version</b> 3.0.0
          </div>
          <strong>Copyright © 2022 <a href="https://codigoblanco.mx" className='link-footer'>Código Blanco</a>.</strong> Todos los derechos reservados.
        </footer>
      </ErrorBoundary>
    );
  }
}

export default App;

App.propTypes = {
  children: PropTypes.node,
  router: PropTypes.object,
  location: PropTypes.object
};
