import React, { Suspense, useRef } from 'react';
import { Route } from 'react-router';
import { requireAuth } from './utils/router';
import App from './containers/App';
// import Dashboard from 'containers/Dashboard';
import PublicRoute from './pages/router/PublicRoute';
import PrivateRoute from './pages/router/PrivateRoute';
import { useAuthContext } from 'context/authContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const LazyLoad = (props, Component) => {
  const { openAlert, notity, user, agente, wsSend } = useAuthContext();
  let location1 = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component
        alert={openAlert}
        notification={openAlert}
        notity={notity}
        navigate={navigate}
        location={location1}
        params={params}
        wsSend={wsSend}
        user={user}
        agente={agente}
        {...props}
      />
    </Suspense>
  );
};

const Dashboard = (props) => LazyLoad(props, React.lazy(()=> import('containers/Dashboard')));
const FirmarCotizacion = (props) => LazyLoad(props, React.lazy(()=> import('pages/cotizaciones/cliente/FirmarPublica')));
const FirmarContratoEquipo = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/contrato-publico/FirmarPublica.js')));
const Login = (props) => LazyLoad(props, React.lazy(()=> import('pages/application/session/Login')));
const Modules = (props) => LazyLoad(props, React.lazy(()=> import('pages/application/modules')));
const Users = (props) => LazyLoad(props, React.lazy(()=> import('pages/application/users')));
const ApplicationData = (props) => LazyLoad(props, React.lazy(()=> import('pages/application/logs/ApplicationData')));
const Files = (props) => LazyLoad(props, React.lazy(()=> import('pages/application/files')));

const Logout = (props) => LazyLoad(props, React.lazy(()=> import('pages/application/session/Logout')));

const PageNotFound = (props) => LazyLoad(props, React.lazy(()=> import('pages/application/security/PageNotFound')));
const Forbidden = (props) => LazyLoad(props, React.lazy(()=> import('pages/application/security/Forbidden')));

const StoreEquipments = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/store-equipments')));
const StoreJhonnyCars = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/store-jhonnycars')));

const RecepcionCompras = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/recepciones-compras')));
const NuevaRecepcion = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/recepciones-compras/add')));
const EditarRecepcion = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/recepciones-compras/edit')));
const DetalleRecepcion = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/recepciones-compras/detail')));

const SurtidoCarros = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/surtido-carros/')));
const SurtirCarro = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/surtido-carros/add')));


const Traspasos = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/traspasos')));
const NuevoTraspaso = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/traspasos/New')));
const DetalleTraspaso = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/traspasos/detail')));

const Mermas = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/mermas')));
const NuevoMerma = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/mermas/New')));
const DetalleMerma = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/mermas/detail')));

const Ajustes = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/ajustes')));
const NuevoAjuste = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/ajustes/New')));
const DetalleAjuste = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/ajustes/detail')));

const Pedidos = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/pedidos')));
const NuevoPedido = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/pedidos/New')));
const DetallePedido = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/pedidos/detail')));
const EditarPedido = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/pedidos/edit')));


const Conversiones = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/conversiones')));
const NuevoConversion = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/conversiones/New')));
const DetalleConversion = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/conversiones/detail')));
const EditarConversion = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/conversiones/edit')));


const Transations = (props) => LazyLoad(props, React.lazy(()=> import('pages/transations/transations')));
const References = (props) => LazyLoad(props, React.lazy(()=> import('pages/transations/references')));

// Clientes


const ListCustomerRegistros = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers_registros')));
const AddCustomer = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/add')));
const EditCustomer = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/edit')));
const ListCustomer = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/list')));
const DetailCustomer = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/detail')));
const ContractByCustomer = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/contract')));
const EditarContrato = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/contract/Edit')));
const QuotationByCustomer = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/quotation')));
const Ubicaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/ubicaciones')));
const ImportClientes = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/import')));
const UnificarClientes = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/unificar')));
const Domicilios = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/domicilios')));
const Contactos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/contactos')));
const ContactosClientes = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/contactos/ClientesContacto')));

const RuletaAsesores = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/ruleta-asesores')));

const SeguimientoProspectos = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/seguimientos/SeguimientoProspectos') ));
const OrdenesServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio') ));
const OrdenesServicioVisitas = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicios-visitas') ));
const NuevoOrdenServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio/New') ));
const EditarOrdenServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio/Edit') ));
const VisitasOrdenServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio/visitas/index') ));

const ObservacionesOrdenServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio/observaciones/observaciones') ));
const ObservacionesConclusionesOrdenServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio/observaciones/conclusiones') ));
const ObservacionesComentariosOrdenServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio/observaciones/comentarios') ));
const CalificacionServicioOrdenServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio/calificacion_servicio/index') ));
const FirmaClienteServicioOrdenServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio/firmas/cliente') ));
const FirmaAgenteOrdenServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio/firmas/agente') ));

const DetalleOrdenServicio = (props) => LazyLoad(props, React.lazy(()=> import('pages/ordenes-servicio/detail') ));

const Existencias = (props) => LazyLoad(props, React.lazy(()=> import('pages/store/existencias') ));

const AddAgendaMonitoreo = (props) => LazyLoad(props, React.lazy(()=> import('pages/agenda-monitoreo/add/New')));
const UptAgendaMonitoreo = (props) => LazyLoad(props, React.lazy(()=> import('pages/agenda-monitoreo/upt/Edit')));
const ListAgendaMonitoreo = (props) => LazyLoad(props, React.lazy(()=> import('pages/agenda-monitoreo')));
const ImportarAgendaMonitoreo = (props) => LazyLoad(props, React.lazy(()=> import('pages/agenda-monitoreo/Importar')));

const ClientesSaldos = (props) => LazyLoad(props, React.lazy(()=> import('pages/customers/customers-saldos')));

// Catalogos

const ListasPrecios = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/listas-precios')));
const UnitMeasures = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/unit-measures')));
const Products = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/products')));
const NewProduct = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/products/NewProduct')));

const Configurations = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/configuration')));
const ConfigurationDetail = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/configuration/ConfigurationDetail')));

const Characteristics = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/characteristics')));
const NewCharacteristics = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/characteristics/NewCharacteristics')));
const DetailCharacteristics = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/characteristics/detail/DetailCharacteristics')));
const EditCharacteristics = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/characteristics/EditCharacteristics')));

const PreciosEquipos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/precios-equipos')));

const Equipments = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/equipments')));
const NewEquipment = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/equipments/NewEquipment')));
const ImportEquipment = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/equipments/import')));
const DetailEquipment = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/equipments/detail/DetailEquipment')));
const EditEquipments = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/equipments/EditEquipments')));
const EditPreciosEquipments = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/equipments/EditPreciosEquipments')));
const RendimientosEquipment = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/equipments/rendimientos')));

const Services = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/services/')));
const NewService = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/services/NewService')));
const DetailService = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/services/detail/DetailService')));
const EditServices = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/services/EditServices')));
const ImportServices = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/services/import')));

const Sellers = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/sellers')));

const StoreHouse = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/storehouse')));
const Sucursales = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/sucursales')));
const Providers = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/providers')));

const EquiposSeguridadElectronica = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/equipos-seguridad-electronica')));
const TipoEstablecimientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipo-establecimientos')));
const Rubros = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/rubros')));
const TipoCableados = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipo-cableados')));
const TipoServicios = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipo-servicios')));

const FormaPago = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/formas-pago')));
const CondicionesPagoEquipos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/condiciones-equipos')));
const CondicionesPagoServicios = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/condiciones-servicios')));

const EsquemasPago = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/esquemas-pago')));
const Plazos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/plazos')));
const Anticipos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/anticipos')));

const TiposContactos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos-contactos')));
const Agentes = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/agentes')));
const AgentesClientes = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/agentes/AgentesClientes')));
const AgentesProspectos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/agentes/AgentesProspectos')));
const Titulos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/titulos')));

const ClasificadoresCotizaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/clasificadores-cotizaciones')));
const ClasificadoresCotizacionesImportar = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/clasificadores-cotizaciones/import')));
const ClasificadoresLevantamientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/clasificadores-levantamientos')));

const Kits = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/kits')));
const NewKits = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/kits/NewKits')));
const EditKits = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/kits/EditKits')));
const ImportKits = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/kits/import')));

const KitsComerciales = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/kits-comerciales')));
const NewKitsComerciales = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/kits-comerciales/NewKits')));
const EditKitsComerciales = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/kits-comerciales/EditKits')));
const ImportKitsComerciales = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/kits-comerciales/import')));

const Recetas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/recetas')));
const NewRecetas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/recetas/New')));
const EditRecetas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/recetas/Edit')));

const Exceptions = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/exceptions')));
const TipoCambios = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipo-cambios')));
const Bancos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/bancos')));
const CuentasBancarias = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/cuentas-bancarias')));
const Motivos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/motivos')));
const ResultadosVisitas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/resultados-visitas')));
const Documentos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/documentos')));
const UbicacionesEquipos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/ubicaciones')));
const Marcas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/marcas')));
const EquipmentsCharacteristics = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/equipments-characteristics')));

const CondicionesVentas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/condiciones-ventas')));
const CondicionesInstalaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/condiciones-instalaciones')));
const CondicionesGarantias = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/condiciones-garantias')));
const AvisoPrivacidad = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/aviso-privacidad')));
const ClasificadoresProveedores = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/clasificadores-proveedores')));
const CondicionesContratos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/condiciones-contratos')));
const FichasTecnicas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/fichas-tecnicas')));

const Fabricantes = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/fabricantes')));
const ControlesMantenimientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/controles-mantenimientos')));
const MaquinariaHerramientas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/maquinaria-herramientas')));
const Piezas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/piezas')));
const TiposPiezas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos-piezas')));
const ServiciosTerceros = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/servicios-terceros')));
const TiposMonitoreos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos-monitoreos')));
const CuentasMonitoreos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/cuentas-monitoreos')));
const ImportarCuentasMonitoreos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/cuentas-monitoreos/Importar')));

const TiposLevantamientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos-levantamientos')));
const TiposOrdenesServicios = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos-ordenes-servicios')));
const ConfiguracionesCarrusel = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/configuraciones_carrusel')));
const DocumentosEmpleados = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/documentos-empleados')));
const DescuentosPagos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/descuentos-pagos')));

const Estados = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/estados')));
const Paises = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/paises')));
const Colonias = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/colonias')));
const ImportarColonias = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/colonias/import')));

const Municipios = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/municipios')));
const ImportarMunicipios = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/municipios/import')));

const Soluciones_Solicitadas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/soluciones_solicitadas')));
const Tipos_Servicios_Solicitados = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos_servicios_solicitados')));
const Tipos_Mano_Obra = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos_mano_obra')));
const Tipos_Cobro_Equipo = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos_cobro_equipo')));
const Dealers = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/dealers')));

const MediosComunicaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/medios-comunicaciones')));

const CustomersClasifications = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/customers-clasifications')));

const TiposRemisiones = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos-remisiones')));

const TiposFacturas = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos-facturas')));


// Levantamientos

const Levantamientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/levantamientos') ));
const NuevoLevantamiento = (props) => LazyLoad(props, React.lazy(()=> import('pages/levantamientos/Nuevo') ));
const EditarLevantamiento = (props) => LazyLoad(props, React.lazy(()=> import('pages/levantamientos/Editar') ));
const GenerarCotizacionLevantamiento = (props) => LazyLoad(props, React.lazy(()=> import('pages/levantamientos/GenerarCotizacion') ));

// Cotizaciones

const Cotizaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/cotizaciones') ));
const CotizacionesDetalle = (props) => LazyLoad(props, React.lazy(()=> import('pages/cotizaciones/detalle') ));
const NuevaCotizacion = (props) => LazyLoad(props, React.lazy(()=> import('pages/cotizaciones/New') ));
const NuevaCotizacionRapida = (props) => LazyLoad(props, React.lazy(()=> import('pages/cotizaciones/FastNew') ));
const EditarCotizacion = (props) => LazyLoad(props, React.lazy(()=> import('pages/cotizaciones/Edit') ));
const ConvertirCotizacion = (props) => LazyLoad(props, React.lazy(()=> import('pages/cotizaciones/ConvertirCotizacion') ));

// Remisiones

const Remisiones = (props) => LazyLoad(props, React.lazy(()=> import('pages/remisiones') ));
const RemisionesDetalle = (props) => LazyLoad(props, React.lazy(()=> import('pages/remisiones/detalle') ));
const NuevaRemision = (props) => LazyLoad(props, React.lazy(()=> import('pages/remisiones/New') ));
const EditarRemision = (props) => LazyLoad(props, React.lazy(()=> import('pages/remisiones/Edit') ));

const Compras  = (props) => LazyLoad(props, React.lazy(()=> import('pages/compras/compras')));
const NuevaCompra  = (props) => LazyLoad(props, React.lazy(()=> import('pages/compras/compras/New')));
const EditarCompra  = (props) => LazyLoad(props, React.lazy(()=> import('pages/compras/compras/Edit')));
const DetalleCompra  = (props) => LazyLoad(props, React.lazy(()=> import('pages/compras/compras/detail')));
const SolicitudesCompras  = (props) => LazyLoad(props, React.lazy(()=> import('pages/compras/solicitudes-compras')));
const NuevaSolicitudCompra  = (props) => LazyLoad(props, React.lazy(()=> import('pages/compras/solicitudes-compras/New')));
const EditarSolicitudCompra  = (props) => LazyLoad(props, React.lazy(()=> import('pages/compras/solicitudes-compras/Edit')));
const DetalleSolicitudCompra  = (props) => LazyLoad(props, React.lazy(()=> import('pages/compras/solicitudes-compras/detail')));
const TiposCompras  = (props) => LazyLoad(props, React.lazy(()=> import('pages/compras/tipos-compras')));

const Preconciliaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/preconciliaciones')));
const NewPreconciliacion = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/preconciliaciones/New')));
const DetallePreconciliacion = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/preconciliaciones/detail')));
const DetalleConciliacionClientes = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/preconciliaciones/detail-clientes/')));
const DetalleConciliacionPendientes = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/preconciliaciones/depositos-pendientes')));

const Facturas = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/facturas')));
const DetalleFactura = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/facturas/detail')));
const ImportarFactura = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/facturas/import')));
const ImportarFacturaArchivo = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/facturas/import-archivo')));
const ImportarFacturaFaltantes = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/facturas/importar-faltantes')));

const NotasCredito = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/notas_creditos')));
const DetalleNotaCredito = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/notas_creditos/detail')));
const ImportarNotasCreditos = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/notas_creditos/import')));

const CarteraVencida = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/cartera-vencida')));
const ImportarCarteraVencida = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/cartera-vencida/Importar')));

const EstadosCuentas = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/estados-cuentas')));
const ImportarEstadosCuentas = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/estados-cuentas/Importar')));

const Asignaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/asignaciones')));
const ImportarAsignaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/asignaciones/Importar')));
const DetalleAsignaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/asignaciones/detalle')));

const Seguimientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/seguimientos')));
const Seguimiento = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/seguimientos/Seguimiento')));
const AgendaSeguimiento = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/agenda-seguimientos')));
const AgendaVisitas = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/agenda-visitas')));

const Pagos = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/pagos')));
const DetallePago = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/pagos/detail')));
const ImportarPagos = (props) => LazyLoad(props, React.lazy(()=> import('pages/cartera/pagos/import')));


const AddProspecto = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/prospectos/add')));
const EditProspecto = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/prospectos/edit')));
const ListProspecto = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/prospectos/list')));
const DetailProspecto = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/prospectos/detail')));

const RegisterKitServicios = (props) => LazyLoad(props, React.lazy(()=> import('pages/kit-servicios/register')));
const EditKitServicios = (props) => LazyLoad(props, React.lazy(()=> import('pages/kit-servicios/edit')));
const KitServicios = (props) => LazyLoad(props, React.lazy(()=> import('pages/kit-servicios/list/KitServicios')));

const RegisterMantenimientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/mantenimientos/mantenimiento/register')));
const EditMantenimientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/mantenimientos/mantenimiento/edit')));
const Mantenimientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/mantenimientos/mantenimiento/list/Mantenimientos')));

const RegisterPlanesMantenimientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/planes-mantenimientos/planes/register')));
const EditPlanesMantenimientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/planes-mantenimientos/planes/edit')));
const PlanesMantenimientos = (props) => LazyLoad(props, React.lazy(()=> import('pages/planes-mantenimientos/planes/list/PlanesMantenimientos')));


const TiposPedidos = (props) => LazyLoad(props, React.lazy(()=> import('pages/catalogs/tipos_pedidos')));

const Tareas = (props) => LazyLoad(props, React.lazy(()=> import('pages/tareas')));
const Kpis = (props) => LazyLoad(props, React.lazy(()=> import('pages/kpis')));
const Mensajes = (props) => LazyLoad(props, React.lazy(()=> import('pages/mensajes')));
const Notificaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/notificaciones')));

const Departamentos = (props) => LazyLoad(props, React.lazy(()=> import('pages/nomina/departamentos')));
const Puestos = (props) => LazyLoad(props, React.lazy(()=> import('pages/nomina/puestos')));
const Empleados = (props) => LazyLoad(props, React.lazy(()=> import('pages/nomina/empleados')));
const TiposNominas = (props) => LazyLoad(props, React.lazy(()=> import('pages/nomina/tiposnominas')));
const MatrizVacaciones = (props) => LazyLoad(props, React.lazy(()=> import('pages/nomina/matrizvacaciones')));
const GruposEmpleados = (props) => LazyLoad(props, React.lazy(()=> import('pages/nomina/grupos_empleados')));
const Nomina = (props) => LazyLoad(props, React.lazy(()=> import('pages/nomina/nomina')));

const ConfiguracionGeneral = (props) => LazyLoad(props, React.lazy(()=> import('pages/configuraciones/generales')));
const EstructuraCuenta = (props) => LazyLoad(props, React.lazy(()=> import('pages/configuraciones/generales/estructura-cuenta')));

const ActivityUser = (props) => LazyLoad(props, React.lazy(()=> import('pages/application/users/ActivityUser')));
const Indicadores = (props) => LazyLoad(props, React.lazy(()=> import('pages/reportes/indicadores')));
const HorasDeTrabajo = (props) => LazyLoad(props, React.lazy(()=> import('pages/reportes/workforces')));

export const RoutesApp = (
  <Route element={<App/>} >
    <Route path='/' element={<PublicRoute/>} >
      <Route path='login' element={<Login/>} />
      <Route path='firmarCotizacion/:hash' element={<FirmarCotizacion/>} />
      <Route path='firmarContrato/:hash' element={<FirmarContratoEquipo/>} />
    </Route>
    <Route path='/' element={<PrivateRoute/>} >
      <Route index element={<Dashboard/>} />
      { /* INICIO */}
      <Route path='dashboard' element={<Dashboard/>} />
      <Route onEnter={requireAuth} path='tareas' element={<Tareas/>} />
      <Route path='kpis' element={<Kpis/>} />
      <Route onEnter={requireAuth} path='mensajes' element={<Mensajes/>} />
      <Route onEnter={requireAuth} path='notificaciones' element={<Notificaciones/>} />

      { /* CONFIGURACION */}
      <Route onEnter={requireAuth} path='modulos' element={<Modules/>} />
      <Route onEnter={requireAuth} path='aplicacion' element={<ApplicationData/>} />
      <Route onEnter={requireAuth} path='files' element={<Files/>} />
      <Route onEnter={requireAuth} path='excepciones' element={<Exceptions/>} />
      <Route onEnter={requireAuth} path='usuarios' element={<Users/>} />
      <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'listado-actividades')} path='usuarios/actividad/:listadoID' element={<ActivityUser/>} />

      { /* CARTERA */}
      <Route  path='conciliaciones' >
        <Route index onEnter={requireAuth} element={<Preconciliaciones/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='conciliar' element={<NewPreconciliacion/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:conciliacionId' element={<DetallePreconciliacion/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detallado-clientes')} path='detallado-clientes/:conciliacionId' element={<DetalleConciliacionClientes/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'pendientes')} path='pendientes/:conciliacionId' element={<DetalleConciliacionPendientes/>} />
      </Route>
      <Route path='facturas' >
        <Route index onEnter={requireAuth} element={<Facturas/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'facturacion')} path='facturacion' element={<NewPreconciliacion/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:facturaId' element={<DetalleFactura/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportarFactura/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar-archivo' element={<ImportarFacturaArchivo/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar-faltantes' element={<ImportarFacturaFaltantes/>} />
      </Route>
      <Route path='notas-creditos' >
        <Route index onEnter={requireAuth} element={<NotasCredito/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:notaCreditoId' element={<DetalleNotaCredito/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportarNotasCreditos/>} />
      </Route>
      <Route path='pagos' >
        <Route index onEnter={requireAuth} element={<Pagos/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:pagoId' element={<DetallePago/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportarPagos/>} />
      </Route>
      <Route path='seguimientos'>
        <Route index onEnter={requireAuth} element={<Seguimientos/>}  />
        <Route path='editar/:seguimientoId' element={<Seguimientos/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'seguimiento')} path='seguimiento/:clienteId' element={<Seguimiento/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'seguimiento-prospecto')} path='seguimiento-prospecto/:prospectoId' element={<SeguimientoProspectos/>} />
      </Route>
      <Route onEnter={requireAuth} path='agenda-seguimientos' element={<AgendaSeguimiento/>} />
      <Route onEnter={requireAuth} path='agenda-visitas' element={<AgendaVisitas/>} />
      
      <Route path='estados-cuentas'>
        <Route index onEnter={requireAuth} element={<EstadosCuentas/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportarEstadosCuentas/>} />
      </Route>
      <Route path='cartera-vencida'>
        <Route index onEnter={requireAuth} element={<CarteraVencida/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:facturaId' element={<DetalleFactura/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportarCarteraVencida/>} />
      </Route>

      <Route path='asignaciones'>
        <Route index onEnter={requireAuth} element={<Asignaciones/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle-agente')} path='detalle/:agenteId' element={<DetalleAsignaciones/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportarAsignaciones/>} />
      </Route>

      <Route onEnter={requireAuth} path='motivos-visitas' element={<Motivos/>} />
      <Route onEnter={requireAuth} path='resultados-visitas' element={<ResultadosVisitas/>} />

      { /* CLIENTES */}
      <Route path='levantamientos'>
        <Route index onEnter={requireAuth} element={<Levantamientos/>}  />
        <Route exact path='nuevo' element={< NuevoLevantamiento/> } />
        <Route exact path='edicion/:levantamientoId' element={< EditarLevantamiento/> } />
        <Route exact path='cotizacion/:levantamientoId' element={< GenerarCotizacionLevantamiento/> } />
      </Route>

      <Route path='cotizaciones'>
        <Route index onEnter={requireAuth} element={<Cotizaciones/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} exact path='nuevo-rapida' element={< NuevaCotizacionRapida/> } />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} exact path='nuevo-rapida/:tipoCliente/:customerId' element={< NuevaCotizacionRapida/> } />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} exact path='nuevo/:tipoCliente/:customerId' element={< NuevaCotizacion/> } />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} exact path='nuevo' element={< NuevaCotizacion/> } />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:levantamientoId' element={<EditarCotizacion/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path=':quotationId' element={<CotizacionesDetalle/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='convertir/:levantamientoId' element={<ConvertirCotizacion/>} />
      </Route>

      <Route path='remisiones'>
        <Route index onEnter={requireAuth} element={<Remisiones/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} exact path='nuevo/:tipoCliente/:customerId' element={< NuevaRemision/> } />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} exact path='nuevo' element={< NuevaRemision/> } />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:remisionId' element={<EditarRemision/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path=':quotationId' element={<RemisionesDetalle/>} />
      </Route>
      <Route path='clientes-registros' >
        <Route index onEnter={requireAuth} element={<ListCustomerRegistros/>}  />
      </Route>
      <Route path='clientes' >
        <Route index onEnter={requireAuth} element={<ListCustomer/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='registro' element={<AddCustomer/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='editar/:customerId' element={<EditCustomer/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'ubicaciones-contactos')} path='ubicaciones/:customerId/contactos' element={<Ubicaciones/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportClientes/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:customerId' element={<DetailCustomer/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle-contratos')} path='detalle/:customerId/contrato/:documentoId' element={<ContractByCustomer/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle-cotizacion')} path='detalle/:customerId/cotizacion/:quotationId' element={<QuotationByCustomer/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='unificar' element={<UnificarClientes/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path=':customerId/editar-contrato/:adjuntoId' element={<EditarContrato/>} />
      </Route>

      <Route path='prospectos' >
        <Route index onEnter={requireAuth} element={<ListProspecto/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='registro' element={<AddProspecto/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='editar/:prospectoId' element={<EditProspecto/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:prospectoId' element={<DetailProspecto/>} />
      </Route>

      <Route path='agenda-monitoreo' >
        <Route index onEnter={requireAuth} element={<ListAgendaMonitoreo/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='crear' element={<AddAgendaMonitoreo/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='editar/:agenda_monitoreo_Id' element={<UptAgendaMonitoreo/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportarAgendaMonitoreo/>} />
      </Route>
      
      <Route onEnter={requireAuth} path='domicilios' element={<Domicilios/>} />
      <Route onEnter={requireAuth} path='contactos' element={<Contactos/>} />
      <Route onEnter={requireAuth} path='contactos/clientes/:contactoId' element={<ContactosClientes/>} />

      <Route path='clientes-saldos' element={<ClientesSaldos/>} />

      <Route onEnter={requireAuth} path='ruleta-asesores' element={<RuletaAsesores/>} />

      { /* TRANSACCIONES */}
      <Route onEnter={requireAuth} path='movimientos' element={<Transations/>} />
      <Route onEnter={requireAuth} path='referencias' element={<References/>} />

      { /* COMPRAS */}

      <Route onEnter={requireAuth} path="tipos-compras" element={<TiposCompras/>} />

      <Route path="compras">
        <Route index onEnter={requireAuth} element={<Compras/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} exact  path="nueva" element={<NuevaCompra/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path=":compraId" element={<DetalleCompra/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path="edicion/:compraId" element={<EditarCompra/>} />
      </Route>

      <Route path="solicitudes-compras" >
        <Route index onEnter={requireAuth} element={<SolicitudesCompras/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path="nueva" element={<NuevaSolicitudCompra/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:solicitudCompraId' element={<DetalleSolicitudCompra/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path="edicion/:solicitudCompraId" element={<EditarSolicitudCompra/>} />
      </Route>

      <Route path='recepciones-compras'>
        <Route index onEnter={requireAuth} element={<RecepcionCompras/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NuevaRecepcion/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:recepcionCompraId' element={<EditarRecepcion/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path=':recepcionCompraId' element={<DetalleRecepcion/>} />
      </Route>


      { /* ALMACEN */}

      <Route onEnter={requireAuth} path='almacen' element={<StoreEquipments/>} />
      <Route onEnter={requireAuth} path='stock-carros' element={<StoreJhonnyCars/>} />

      <Route path='surtido-carros' >
        <Route index onEnter={requireAuth} element={<SurtidoCarros/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'surtir')} path='surtir' element={<SurtirCarro/>} />
      </Route>

      { /* INVENTARIO */}

      <Route onEnter={requireAuth} path='ubicaciones' element={<UbicacionesEquipos/>} />
      <Route onEnter={requireAuth} path='marcas' element={<Marcas/>} />
      <Route onEnter={requireAuth} path='equipments-characteristics' element={<EquipmentsCharacteristics/>} />
      <Route onEnter={requireAuth} path='fichas-tecnicas' element={<FichasTecnicas/>} />
      <Route onEnter={requireAuth} path='unidades-medidas' element={<UnitMeasures/>} />
      <Route onEnter={requireAuth} path='listas-precios' element={<ListasPrecios/>} />

      <Route onEnter={requireAuth} path='precios-equipos' element={<PreciosEquipos/>} />
    
      <Route path='equipos'>
        <Route index onEnter={requireAuth} element={<Equipments/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NewEquipment/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportEquipment/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:equipmentId' element={<DetailEquipment/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:equipmentId' element={<EditEquipments/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/precios/:equipmentId' element={<EditPreciosEquipments/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='rendimientos' element={<RendimientosEquipment/>} />
      </Route>

      <Route path='servicios'>
        <Route index onEnter={requireAuth} element={<Services/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NewService/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:serviceId' element={<DetailService/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:serviceId' element={<EditServices/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportServices/>} />
      </Route>

      <Route path='kits' >
        <Route index onEnter={requireAuth} element={<Kits/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NewKits/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:kitId' element={<EditKits/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportKits/>} />
      </Route>

      <Route path='kits-comerciales' >
        <Route index onEnter={requireAuth} element={<KitsComerciales/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NewKitsComerciales/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:kitId' element={<EditKitsComerciales/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportKitsComerciales/>} />
      </Route>

      <Route path='recetas' >
        <Route index onEnter={requireAuth} element={<Recetas/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NewRecetas/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:recetaId' element={<EditRecetas/>} />
      </Route>

      <Route path='tipos_pedidos' >
        <Route index onEnter={requireAuth} element={<TiposPedidos/>} />
      </Route>

      <Route path='clasificadores-cotizaciones'  >
        <Route index onEnter={requireAuth} element={<ClasificadoresCotizaciones/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext) => requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ClasificadoresCotizacionesImportar/>} />
      </Route>
      <Route path='clasificadores-levantamientos' >
        <Route index onEnter={requireAuth} element={<ClasificadoresLevantamientos/>}  />
      </Route>

      <Route path='configuraciones' >
        <Route index onEnter={requireAuth} element={<Configurations/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path=':configurationId' element={<ConfigurationDetail/>} />
      </Route>

      <Route path='caracteristicas'>
        <Route index onEnter={requireAuth} element={<Characteristics/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nueva' element={<NewCharacteristics/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:characteristicsId' element={<DetailCharacteristics/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:characteristicsId' element={<EditCharacteristics/>} />
      </Route>

      <Route path='ordenes-traspasos' >
        <Route index onEnter={requireAuth} element={<Traspasos/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NuevoTraspaso/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:traspasoId' element={<DetalleTraspaso/>} />
      </Route>
      
      <Route path='ordenes-servicios-visitas' >
        <Route index onEnter={requireAuth} element={<OrdenesServicioVisitas/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'observaciones')} path='observaciones/:visitaId' element={<ObservacionesOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'observaciones-conclusiones')} path='observaciones-conclusiones/:visitaId' element={<ObservacionesConclusionesOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'observaciones-comentarios')} path='observaciones-comentarios/:visitaId' element={<ObservacionesComentariosOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'calificaciones')} path='calificaciones/:visitaId' element={<CalificacionServicioOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'firma_cliente')} path='firma-cliente/:visitaId' element={<FirmaClienteServicioOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'firma_agente')} path='firma-agente/:visitaId' element={<FirmaAgenteOrdenServicio/>} />
        {/* <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NuevoOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='editar/:ordenServicioId' element={<EditarOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'observaciones')} path='observaciones/:ordenServicioId' element={<ObservacionesOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'calificacion_servicio')} path='calificacion_servicio/:ordenServicioId' element={<CalificacionServicioOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:ordenServicioId' element={<DetalleOrdenServicio/>} /> */}
      </Route>
      <Route path='ordenes-servicio' >
        <Route index onEnter={requireAuth} element={<OrdenesServicio/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NuevoOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='editar/:ordenServicioId' element={<EditarOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'visitas')} path='visitas/:ordenServicioId' element={<VisitasOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'observaciones')} path='observaciones/:ordenServicioId' element={<ObservacionesOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'calificacion_servicio')} path='calificacion_servicio/:ordenServicioId' element={<CalificacionServicioOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:ordenServicioId' element={<DetalleOrdenServicio/>} />
      </Route>

      <Route path='pedidos' >
        <Route index onEnter={requireAuth} element={<Pedidos/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NuevoPedido/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:pedidoId' element={<DetallePedido/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='edicion/:pedidoId' element={<EditarPedido/>} />
      </Route>

      <Route path='conversiones' >
        <Route index onEnter={requireAuth} element={<Conversiones/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NuevoConversion/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:conversionId' element={<DetalleConversion/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='edicion/:conversionId' element={<EditarConversion/>} />
      </Route>

      <Route path='ordenes-mermas' >
        <Route index onEnter={requireAuth} element={<Mermas/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NuevoMerma/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:mermaId' element={<DetalleMerma/>} />
      </Route>

      <Route path='ajustes' >
        <Route index onEnter={requireAuth} element={<Ajustes/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NuevoAjuste/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:ajusteId' element={<DetalleAjuste/>} />
      </Route>

      <Route path='existencias' >
        <Route index onEnter={requireAuth} element={<Existencias/>}  />
      </Route>


      { /* MANTENIMIENTO */}

      <Route path='planes-mantenimientos' >
        <Route index onEnter={requireAuth} element={<PlanesMantenimientos/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='captura' element={<RegisterPlanesMantenimientos/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:planMantenimientoId' element={<EditPlanesMantenimientos/>} />
      </Route>

      <Route path='mantenimientos' >
        <Route index onEnter={requireAuth} element={<Mantenimientos/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='captura' element={<RegisterMantenimientos/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:mantenimientoId' element={<EditMantenimientos/>} />
      </Route>

      <Route path='kit-servicios' >
        <Route index onEnter={requireAuth} element={<KitServicios/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='captura' element={<RegisterKitServicios/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='edicion/:kitServicioId' element={<EditKitServicios/>} />
      </Route>


      <Route onEnter={requireAuth} path="maquinaria-herramientas" element={<MaquinariaHerramientas/>} />
      <Route onEnter={requireAuth} path="servicios-terceros" element={<ServiciosTerceros/>} />
      <Route onEnter={requireAuth} path="controles-mantenimientos" element={<ControlesMantenimientos/>} />
      <Route onEnter={requireAuth} path="tipos-piezas" element={<TiposPiezas/>} />
      <Route onEnter={requireAuth} path="piezas" element={<Piezas/>} />
      <Route onEnter={requireAuth} path="fabricantes" element={<Fabricantes/>} />
      <Route onEnter={requireAuth} path='esquemas-pago' element={<EsquemasPago/>} />


      { /* VENDEDORES */}

      <Route onEnter={requireAuth} path='vendedores' element={<Sellers/>} />

      { /* CATALOGOS */}

      <Route path='agentes' >
        <Route index onEnter={requireAuth} element={<Agentes/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'clientes')} path='clientes/:agenteId' element={<AgentesClientes/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'prospectos')} path='prospectos/:agenteId' element={<AgentesProspectos/>} />
      </Route>

      <Route onEnter={requireAuth} path="tipos-servicios" element={<TipoServicios/>} />

      <Route onEnter={requireAuth} path='plazos' element={<Plazos/>} />
      <Route onEnter={requireAuth} path='anticipos' element={<Anticipos/>} />

      <Route onEnter={requireAuth} path='titulos' element={<Titulos/>} />

      <Route onEnter={requireAuth} path='tipo-cambio' element={<TipoCambios/>} />
      <Route onEnter={requireAuth} path='bancos' element={<Bancos/>} />
      <Route onEnter={requireAuth} path='cuentas-bancarias' element={<CuentasBancarias/>} />

      <Route onEnter={requireAuth} path='documentos' element={<Documentos/>} />

      <Route onEnter={requireAuth} path='condiciones-ventas' element={<CondicionesVentas/>} />
      <Route onEnter={requireAuth} path='condiciones-instalacion' element={<CondicionesInstalaciones/>} />
      <Route onEnter={requireAuth} path='condiciones-garantia' element={<CondicionesGarantias/>} />
      <Route onEnter={requireAuth} path='aviso-privacidad' element={<AvisoPrivacidad/>} />
      <Route onEnter={requireAuth} path='clasificacion-proveedores' element={<ClasificadoresProveedores/>} />
      <Route onEnter={requireAuth} path='condiciones-contratos' element={<CondicionesContratos/>} />

      <Route onEnter={requireAuth} path="descuentos-pagos" element={<DescuentosPagos/>} />
      <Route onEnter={requireAuth} path="estados" element={<Estados/>} />
      <Route onEnter={requireAuth} path="paises" element={<Paises/>} />
      <Route path='colonias' >
        <Route index onEnter={requireAuth} element={<Colonias/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='importar' element={<ImportarColonias/>} />
      </Route>
      <Route path='municipios' >
        <Route index onEnter={requireAuth} element={<Municipios/>}  />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='importar' element={<ImportarMunicipios/>} />
      </Route>

      <Route onEnter={requireAuth} path="equipos-seguridad-electronica" element={<EquiposSeguridadElectronica/>} />
      <Route onEnter={requireAuth} path="condiciones-pagos-equipos" element={<CondicionesPagoEquipos/>} />
      <Route onEnter={requireAuth} path='tipos-cableados' element={<TipoCableados/>} />
      <Route onEnter={requireAuth} path='tipos-remisiones' element={<TiposRemisiones/>} />
      <Route onEnter={requireAuth} path='tipos_facturas' element={<TiposFacturas/>} />
      <Route onEnter={requireAuth} path='formas-pago-equipos' element={<FormaPago/>} />

      <Route onEnter={requireAuth} path="condiciones-pagos-servicios" element={<CondicionesPagoServicios/>} />
      <Route onEnter={requireAuth} path='tipos-monitoreos' element={<TiposMonitoreos/>} />

      <Route onEnter={requireAuth} path='cuentas-monitoreos'>
        <Route index onEnter={requireAuth} element={<CuentasMonitoreos/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'importar')} path='importar' element={<ImportarCuentasMonitoreos/>} />
      </Route>
      
      <Route onEnter={requireAuth} path='tipos-levantamientos' element={<TiposLevantamientos/>} />
      <Route onEnter={requireAuth} path='tipos-ordenes-servicios' element={<TiposOrdenesServicios/>} />
      <Route onEnter={requireAuth} path='configuraciones-carrusel' element={<ConfiguracionesCarrusel/>} />
      <Route onEnter={requireAuth} path='documentos-empleados' element={<DocumentosEmpleados/>} />

      <Route onEnter={requireAuth} path='tipos-contactos' element={<TiposContactos/>} />
      <Route onEnter={requireAuth} path='proveedores' element={<Providers/>} />


      <Route onEnter={requireAuth} path='tipo-establecimientos'>
        <Route index onEnter={requireAuth} element={<TipoEstablecimientos/>} />
        <Route onEnter={(nextState, replace, wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'rubros')} path=':establecimientoId/rubros' element={<Rubros/>} />
      </Route>


      <Route path='productos' >
        <Route index onEnter={requireAuth} element={<Products/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NewProduct/>} />
      </Route>

      <Route onEnter={requireAuth} path='almacenes' element={<StoreHouse/>} />
      <Route onEnter={requireAuth} path='sucursales' element={<Sucursales/>} />
      <Route onEnter={requireAuth} path='soluciones_solicitadas' element={<Soluciones_Solicitadas/>} />
      <Route onEnter={requireAuth} path='tipos_servicios_solicitados' element={<Tipos_Servicios_Solicitados/>} />
      <Route onEnter={requireAuth} path='tipos_mano_obra' element={<Tipos_Mano_Obra/>} />
      <Route onEnter={requireAuth} path='tipos_cobro_equipo' element={<Tipos_Cobro_Equipo/>} />
      <Route onEnter={requireAuth} path='dealers' element={<Dealers/>} />

      <Route onEnter={requireAuth} path='medios-comunicaciones' element={<MediosComunicaciones/>} />
      <Route onEnter={requireAuth} path='clientes-clasificaciones' element={<CustomersClasifications/>} />

      { /* REPORTES */}
      <Route onEnter={requireAuth} path='indicadores' element={<Indicadores/>} />
      <Route onEnter={requireAuth} path='reporte-horas-trabajo' element={<HorasDeTrabajo/>} />
      
      { /* GENERALES */}

      <Route onEnter={requireAuth} path='general' element={<ConfiguracionGeneral/>} />
      <Route onEnter={requireAuth} path='estructura_cuenta' element={<EstructuraCuenta/>} />

      { /* NOMINA */}
      <Route onEnter={requireAuth} path='puestos' element={<Puestos/>} />
      <Route onEnter={requireAuth} path='departamentos' element={<Departamentos/>} />
      <Route onEnter={requireAuth} path='empleados' element={<Empleados/>} />
      <Route onEnter={requireAuth} path='tiposnominas' element={<TiposNominas/>} />
      <Route onEnter={requireAuth} path='nomina' element={<Nomina/>} />
      <Route onEnter={requireAuth} path='grupos-empleados' element={<GruposEmpleados/>} />
      <Route onEnter={requireAuth} path='matrizvacaciones' element={<MatrizVacaciones/>} />


      { /* GENERALES */}
      <Route path='logout' element={<Logout/>} />
      <Route path='sin-autorizacion' element={<Forbidden/>} />
      <Route path="*" element={<PageNotFound/>} />
  
    </Route>
  </Route>

);
