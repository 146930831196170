import { Component } from 'react';
import PropTypes from 'prop-types';
import { hasAccess } from 'utils/permissions';

class IfAccess extends Component {
  render() {
    const { path , children } = this.props;
    if(hasAccess( path) && children)
      return children;
    return false;
  }
}

IfAccess.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string
};

export default IfAccess;
