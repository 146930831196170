import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'stateless';
import { postRequest } from 'utils/api';
import moment from 'moment';
import axios from 'axios';

export default class ErrorBoundary extends Component {

  constructor(props){
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    const { expiration, type, email, name, token } = localStorage;
    const action = this.props.location && this.props.location.pathname ? this.props.location.pathname: '';
    if ( process.env.NODE_ENV === 'production' ) {
      axios.post('https://hooks.slack.com/services/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',
        JSON.stringify({
          text: `Usuario: ${localStorage.email}
Info: ${JSON.stringify(errorInfo)}
Error: ${error ? error.toString(): error}`
        }));
      postRequest({
        url: `exceptions`,
        body: {
          action,
          exception: JSON.stringify({
            errorInfo,
            error: error ? error.toString(): error,
          }),
          session: JSON.stringify({expiration, type, email, name, token}),
          time: moment().format()
        },
        success: () => {

        },
        fail: () => {
          if ( process.env.NODE_ENV === 'production' ) {
            axios.post('https://hooks.slack.com/services/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',
              JSON.stringify({
                text: `Error de comunicación con la API`
              }));
          }
        }
      });
    } else {
      this.setState({
        error: error,
        errorInfo: errorInfo
      });
    }
  }

  render() {
    const { error, errorInfo  } = this.state;

    if ( errorInfo ) {
      return (
        <div className="container well">
          <h2><Icon icon="warning-sign" /> Algo salió mal.</h2>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </div>
        </div>
      );
    }

    return this.props.children;
  }

}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};
