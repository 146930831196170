import React from 'react';
import ReactDOM from 'react-dom';
import { Routes } from "react-router-dom"
import { RoutesApp } from './app/routes';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from 'context/authContext';

ReactDOM.render(
  <AuthContextProvider>
    <BrowserRouter>
      <Routes>
        {RoutesApp}
      </Routes>
    </BrowserRouter>
  </AuthContextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();