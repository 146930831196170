import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAuthContext } from '../../context/authContext';
import { useLocation } from "react-router";

export default function PublicRoute() {
    const { token } = useAuthContext();
    let location = useLocation();
    if (token && location.pathname == '/login') 
        return <Navigate to={'/dashboard'} ></Navigate>;
    return <Outlet/>;
}